<template>
  <div>
    <!-- <div v-if="!window && this.$route.path === '/user/checkout'" class="cursor-pointer" @click="$router.back()">
      <b-icon
          icon="chevron-left"
          size="is-medium">
      </b-icon>
    </div> -->
    <div :class="divClasses">
      <div class="flex mt-2" v-if="data.Editable">
        <div class="flex-1">
          <!-- <all-field v-if="data.Fields" :data="data.Fields" /> -->
          <template v-for="(f, i) in data.Fields" >
            <div :key="i" class="-mx-2 mb-2">
              <div v-if="f.type === 'popupoptions'">
                <b-field :label="f.overrideLabel">
                  <div :class="['p-2 bg-white rounded border-gray-300', { 'live-item-placeholder': !values[f.fieldToSave] }]" @click="openModal(f.modal)">
                    {{values[f.fieldToSave] ? f.source.find(s => s.Oid === values[f.fieldToSave]).Name : f.placeHolder}}
                  </div>
                </b-field>
                <modal v-bind="modalProps(f.modal)" :submit-button="null" :close-button="null" @close="closeModal(f.modal)">
                  <!-- <div class="grid grid-cols-3 gap-4">
                    <div v-for="(o, j) in f.source" class="flex flex-col items-center" :key="j" @click="() => { input(f.fieldToSave, o.Oid); closeModal(f.modal) }">
                      <icon-image :value="o.Icon"></icon-image>
                      <strong>{{o.Name}}</strong>
                    </div>
                  </div> -->
                  <div v-for="(o, j) in f.source" class="flex items-center px-2 py-4 border-b -mx-4" :key="j" @click="() => { input(f.fieldToSave, o.Oid); closeModal(f.modal) }">
                    <vaddress-icon-image :value="o.Icon"></vaddress-icon-image>
                    <strong class="ml-2">{{o.Name}}</strong>
                  </div>

                </modal>
              </div>
              <div v-else-if="f.type === 'money'" class="w-full" >
                <b-field :label="f.overrideLabel">
                  <money-input :placeholder="f.placeHolder" :value="values[f.fieldToSave]" @input="v => input(f.fieldToSave, v)" @blur="blur(f)" :currency="user.CurrencyObj" />
                </b-field>
              </div>
              <div v-else>
                <b-field :label="f.overrideLabel">
                  <input :type="f.type" :placeholder="f.placeHolder" :value="values[f.fieldToSave] ? values[f.fieldToSave] : undefined" @input="v => input(f.fieldToSave, v.target.value)" :class="['w-full p-2']" @blur="blur(f)" />
                </b-field>
              </div>
            </div>
            <!-- <b-field class="w-full" :key="i" :label="f.overrideLabel" :type="{ 'is-danger': !values[f.fieldToSave] && f.validationParams.indexOf('required') !== -1}">
              <div v-if="f.type === 'popupoptions'">
                <b-input type="text" :placeholder="f.placeHolder" :value="values[f.fieldToSave] ? f.source.find(s => s.Oid === values[f.fieldToSave]).Name : ''" readonly="" @click.native="openModal(f.modal)"></b-input>
                <modal v-bind="modalProps(f.modal)" :submit-button="null" :close-button="null" @close="closeModal(f.modal)">
                  <div class="grid grid-cols-3 gap-4">
                    <div v-for="(o, j) in f.source" class="flex flex-col items-center" :key="j" @click="() => { input(f.fieldToSave, o.Oid); closeModal(f.modal) }">
                      <icon-image :value="o.Icon"></icon-image>
                      <strong>{{o.Name}}</strong>
                    </div>
                  </div>
                </modal>
              </div>
              <b-input v-else :type="f.type"  :placeholder="f.placeHolder" :value="values[f.fieldToSave]" @input="v => input(f.fieldToSave, v)"></b-input>
            </b-field> -->
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import IconCheckbox from '../common/IconCheckbox'
// import IconImage from '../common/IconImage'
import Modal from '../common/Modal'
import MoneyInput from '../form/Money'

export default {
  components: {
    // IconCheckbox,
    // IconImage,
    MoneyInput,
    Modal,
    VaddressIconImage: () => import('@/components/vaddress/common/IconImage')
  },
  props: {
    selected: {
      type: Boolean,
      default: false
    },
    data: Object,
    value: Object
  },
  data () {
    return {
      window: window.innerWidth < 767
    }
  },
  methods: {
    select () {
      this.$emit('select', !this.selected)
    },
    input (f, v) {
      console.log(f, v)
      this.change(f, v)
      this.$emit('input', {
        ...this.value,
        [f]: v
      })
    },
    change (f, v) {
      this.$nextTick(() => {
        this.$emit('change', f, v, this.data.Data)
      })
    },
    blur (f) {
      this.$nextTick(() => {
        this.$emit('blur', f.fieldToSave, this.value[f.fieldToSave], this.data.Data)
      })
    },
    showCategoryOptions () {

    }
  },
  computed: {
    divClasses () {
      return ['pt-2 px-2  ', { 'py-2': !this.data.Editable }, this.data.Class]
    },
    showSelectIcon () {
      const { Selectable = true } = this.data
      return Selectable
    },
    values () {
      return this.value || this.data.Data
    }
  }
}
</script>

<style>
  .live-item-placeholder {
    color: rgba(54,54,54,.3)
  }
  .live-item-moneyinput {
    padding-left: 0.5rem;
  }
  .live-item-moneyinput .input, .live-item-moneyinput .input:hover, .live-item-moneyinput .input:focus {
    border: none !important;
    box-shadow: none;
  }
</style>
